var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('div',{staticClass:"banner news-banner"},[_c('img',{attrs:{"src":`${_vm.$url}/banner/5.png`,"alt":""}}),_c('div',{staticClass:"all-title-ani"},[_vm._v("新闻资讯")]),_c('div',{staticClass:"all-title-ani all-ani-delay",staticStyle:{"width":"8rem"}},[_vm._v("汇聚行业动态 全面助力医疗数智化转型")])]),_c('div',{staticClass:"news"},[_c('div',{staticClass:"enterprise"},[_c('div',{staticClass:"All-title"},[_vm._v("企业动态")]),_c('div',{staticClass:"enterprise-box"},[_c('div',{on:{"click":function($event){return _vm.$router.push({path:'info',query: { id: 1 }})}}},[_c('img',{attrs:{"src":`${_vm.$url}/news/1.png`,"alt":""}}),_vm._m(0)]),_c('div',{on:{"click":function($event){return _vm.$router.push({path:'info',query: { id: 2 }})}}},[_c('img',{attrs:{"src":`${_vm.$url}/news/2.png`,"alt":""}}),_vm._m(1)]),_c('div',{on:{"click":function($event){return _vm.$router.push({path:'info',query: { id: 3 }})}}},[_c('img',{attrs:{"src":`${_vm.$url}/news/3.png`,"alt":""}}),_vm._m(2)]),_c('div',{on:{"click":function($event){return _vm.$router.push({path:'info',query: { id: 4 }})}}},[_c('img',{attrs:{"src":`${_vm.$url}/news/4.png`,"alt":""}}),_vm._m(3)]),_c('div',{on:{"click":function($event){return _vm.$router.push({path:'info',query: { id: 5 }})}}},[_c('img',{attrs:{"src":`${_vm.$url}//news/5.png`,"alt":""}}),_vm._m(4)]),_c('div',{on:{"click":function($event){return _vm.$router.push({path:'info',query: { id: 6 }})}}},[_c('img',{attrs:{"src":`${_vm.$url}/news/6.png`,"alt":""}}),_vm._m(5)])])])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("医院管理系统丨ODR助力PDCA循环，共同改善...")]),_c('div',[_vm._v("近年来，在国家卫健委领导下，医院评审工作稳步推进，逐渐从“以结果为导向”向“以问题为导向”转变...")]),_c('div',[_vm._v("2023-02-09")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("“国考”绩效考核如何稳中取胜？")]),_c('div',[_vm._v("公立医院“国考”步伐片刻未歇。继2021与2020年“国考”成绩公布时间仅三个月后，新一轮2022年公立...")]),_c('div',[_vm._v("2023-02-01")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("多省发文！公立医院高质量发展的路该这样走！")]),_c('div',[_vm._v("公立医院高质量发展的路该怎么走？目前安徽、天津、河北等多省已经提交了自己的方案书，涉及智慧医疗...")]),_c('div',[_vm._v("2022-12-08")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("一文教你医院互联互通标准化成熟度测评中对...")]),_c('div',[_vm._v("近日，国家卫健委统计信息中心发布了《2021年度国家医疗健康信息互联互通标准化成熟度测评结果(第一...")]),_c('div',[_vm._v("2022-12-02")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("医院高质量发展需要怎样的HIS系统？")]),_c('div',[_vm._v("同样使用HIS系统，为什么其他医院能提升医院效益，而你的医院却毫无起色？")]),_c('div',[_vm._v("2021-11-09")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("智慧医院建设路上，必须关注的7大信息化评价...")]),_c('div',[_vm._v("智慧医院——一个近些年各大医院都在不断追求的目标。它并不是医院的新物种，而是在一般医院的基础...")]),_c('div',[_vm._v("2021-12-28")])])
}]

export { render, staticRenderFns }