<template>
  <div>
    <Header></Header>
    <div class="banner news-banner">
      <img :src="`${$url}/banner/5.png`" alt="">
      <div class="all-title-ani">新闻资讯</div>
      <div class="all-title-ani all-ani-delay" style="width: 8rem;">汇聚行业动态 全面助力医疗数智化转型</div>
    </div>
    <div class="news">
      <div class="enterprise">
        <div class="All-title">企业动态</div>
        <div class="enterprise-box">
          <div @click="$router.push({path:'info',query: { id: 1 }})">
            <img :src="`${$url}/news/1.png`" alt="">
            <div>
              <div>医院管理系统丨ODR助力PDCA循环，共同改善...</div>
              <div>近年来，在国家卫健委领导下，医院评审工作稳步推进，逐渐从“以结果为导向”向“以问题为导向”转变...</div>
              <div>2023-02-09</div>
            </div>
          </div>
          <div @click="$router.push({path:'info',query: { id: 2 }})">
            <img :src="`${$url}/news/2.png`" alt="">
            <div>
              <div>“国考”绩效考核如何稳中取胜？</div>
              <div>公立医院“国考”步伐片刻未歇。继2021与2020年“国考”成绩公布时间仅三个月后，新一轮2022年公立...</div>
              <div>2023-02-01</div>
            </div>
          </div>
          <div @click="$router.push({path:'info',query: { id: 3 }})">
            <img :src="`${$url}/news/3.png`" alt="">
            <div>
              <div>多省发文！公立医院高质量发展的路该这样走！</div>
              <div>公立医院高质量发展的路该怎么走？目前安徽、天津、河北等多省已经提交了自己的方案书，涉及智慧医疗...</div>
              <div>2022-12-08</div>
            </div>
          </div>
          <div @click="$router.push({path:'info',query: { id: 4 }})">
            <img :src="`${$url}/news/4.png`" alt="">
            <div>
              <div>一文教你医院互联互通标准化成熟度测评中对...</div>
              <div>近日，国家卫健委统计信息中心发布了《2021年度国家医疗健康信息互联互通标准化成熟度测评结果(第一...</div>
              <div>2022-12-02</div>
            </div>
          </div>
          <div @click="$router.push({path:'info',query: { id: 5 }})">
            <img :src="`${$url}//news/5.png`" alt="">
            <div>
              <div>医院高质量发展需要怎样的HIS系统？</div>
              <div>同样使用HIS系统，为什么其他医院能提升医院效益，而你的医院却毫无起色？</div>
              <div>2021-11-09</div>
            </div>
          </div>
          <div @click="$router.push({path:'info',query: { id: 6 }})">
            <img :src="`${$url}/news/6.png`" alt="">
            <div>
              <div>智慧医院建设路上，必须关注的7大信息化评价...</div>
              <div>智慧医院——一个近些年各大医院都在不断追求的目标。它并不是医院的新物种，而是在一般医院的基础...</div>
              <div>2021-12-28</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  components: {
    Header,
    Footer
  }

}
</script>

<style lang="scss">
.news-banner {
  > div:nth-child(2) {
    margin-top: 200px;
  }
}
.news {
  .enterprise {
    background: #f5f6f8;
    padding-top: 100px;
    padding-bottom: 68px;
    .enterprise-box {
      margin-top: 64px;
      > div {
        width: 702px;
        margin: 0 auto 32px;
        img {
          width: 100%;
          vertical-align: middle;
        }
        > div:nth-child(2) {
          height: 242px;
          width: 100%;
          background: #ffffff;
          border-radius: 0px 0px 8px 8px;
          opacity: 1;
          overflow: hidden;
          > div:nth-child(1) {
            font-size: 30px;
            font-weight: 500;
            color: #181818;
            margin-top: 32px;
            margin-left: 24px;
          }
          > div:nth-child(2) {
            width: 654px;
            font-size: 26px;
            font-weight: 400;
            color: #7b7b7b;
            line-height: 40px;
            margin: 20px auto 24px;
          }
          > div:nth-child(3) {
            font-size: 26px;
            font-weight: 400;
            color: #7b7b7b;
            margin-left: 24px;
          }
        }
      }
    }
  }
}
</style>
